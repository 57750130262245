import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { AbstractApiService } from 'src/app/core/pipe/jsonrpc';
import { environment } from 'src/environments/environment';

export enum events_endpoint {
  events = '/app/v1/events',
}

@Injectable({
  providedIn: 'root',
})
export class EventsService extends AbstractApiService<any> {

  public eventData = new BehaviorSubject<any>(null);
  eventData$ = this.eventData.asObservable();

  constructor(
    http: HttpClient,
    private storage: StorageMap
  ) {
    super(http, 'null');
  }

  setStrgEventData(data: any) {
    lastValueFrom(this.storage.set('eventData', data));
    this.eventData.next(data);
  }

  clearStrgEventDat() {
    lastValueFrom(this.storage.delete('eventData'));
    this.eventData.next(null);
  }

  async getEventData() {
    return await lastValueFrom(this.storage.get('eventData'));
  }

  put_withParam(data: any, endpointURL: string, params?:any) {
    let param = new HttpParams({fromObject: params}).toString()
    return lastValueFrom(this.http.put<any>(`${environment.apiUrl}`+(endpointURL ? endpointURL : this.path) + (param ? '?'+param : ''), data))
  }
}
